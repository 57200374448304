import { endpoints } from './constants/endpoints';
import { fliptApi, fliptChartbitApi, fliptFinancialDataApi } from './instance';

import { createEvaluationPayload } from './helpers';
import {
  BooleanEvaluationResponse,
  VariantEvaluationResponse,
} from './types/api';
import { FLIPT_NAMESPACE } from './constants/env';

const getService = (service: string) => {
  if (service === 'chartbit') {
    return fliptChartbitApi;
  }
  if (service === 'financial-data') {
    return fliptFinancialDataApi;
  }
  return fliptApi;
};
// endpoints handlers
export const evaluateBoolean = (
  userId: string,
  flagKey: string,
  namespaceKey?: string,
) => {
  const api = namespaceKey ? getService(namespaceKey) : fliptApi;
  return api.post<BooleanEvaluationResponse>(
    endpoints.evaluate.boolean,
    createEvaluationPayload(userId, flagKey, namespaceKey ?? FLIPT_NAMESPACE),
  );
};

export const evaluateVariant = (
  userId: string,
  flagKey: string,
  namespaceKey?: string,
) => {
  const api = namespaceKey ? getService(namespaceKey) : fliptApi;
  return api.post<VariantEvaluationResponse>(
    endpoints.evaluate.variant,
    createEvaluationPayload(userId, flagKey, namespaceKey ?? FLIPT_NAMESPACE),
  );
};
